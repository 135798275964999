@import "./variables";
@import "./common";

// @media only screen and (max-width: 1365px) {
// }

@media only screen and (max-width: 1600px) {
  .col {
    .row {
      // font-size: 0.85em;
    }
  }

  .singleContactBrick,
  a {
    font-size: 0.85em !important;
  }
}

@media only screen and (max-width: 1365px) {
  .col {
    .row {
      flex-wrap: wrap;
    }
  }

  .dashboardContent {
    flex-wrap: wrap;
  }

  .singleContactBrick,
  a {
    font-size: 1em !important;
  }
}

.iframe-placeholder {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%272962" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="12" text-anchor="middle">Wczytywanie aktualności...</text></svg>') 0px 0px no-repeat;
}

.dashboardContent {
  background-color: #f6f6f9;

  display: flex;
  height: calc(100vh - #{$toolbarHeight});

  overflow-y: auto;

  align-items: stretch;
  align-content: flex-start;

  padding: 5px;
  font-size: 14px;


  .colLeft {
    width: 33% !important;
  }


  .brickframe {
    margin: 10px 10px 0px 10px;
    background-color: #fff;
    border: 1px solid lightgray;
  }

  .col {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;

    .row {
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: stretch;
      align-content: stretch;
    }
  }
}

.singleContactBrick {
  padding: 15px;
  position: relative;

  height: 100%;

  .bcgrImg {
    height: 80%;
    opacity: 0.05;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  a {
    font-size: 14px;
    text-decoration: none;
    color: #000;
  }
}

.colored-icon {
  color: lightgray;
}