@import "./variables";
@import "./common";

$minColumnWidth: 450px;
$spaceBetweenColumns: 50px;

@media (max-width: calc(#{$minColumnWidth} * 2 + #{$spaceBetweenColumns})) {

  .leftColumn,
  .rightColumn {
    padding-right: 0px !important;
    padding-bottom: 25px !important;
    flex-basis: auto !important;
  }
}

.columnsWrapper {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  flex-wrap: wrap;
}

.leftColumn {
  width: 100%;
  padding-right: $spaceBetweenColumns;

  flex-basis: calc(50% - 40px);
  min-width: $minColumnWidth;
}

.rightColumn {
  width: 100%;
  max-height:calc(100vh - #{$toolbarHeight});

  flex-basis: calc(50% - 40px);
  min-width: $minColumnWidth;
  overflow: hidden;
}

.contentWrapperFullHeight {
  max-height:calc(100vh - #{$toolbarHeight} - 5em); // 4em tabstrip header

  &--withExternalScroll {
    @extend .contentWrapperFullHeight;
    overflow-y: scroll;
  }

  &--withTopToolbar {
    @extend .contentWrapperFullHeight;
    max-height:calc(100vh - #{$toolbarHeight} - 5em - 3.5em);
    height: calc(100vh - 6em - 5em - 6.5em);
  }
}