.header {
  height: $headerHeight;

  position: sticky;
  top: 0;
  z-index: 3;

  .SynerdiLogo {
    width: 150px;
  }

  .logoutButton {
    color: $kendo-color-primary;
    cursor: pointer;
  }

  .headerButton {
    cursor: pointer;
  }

  .searchPanel {
    margin-left: auto;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    display: flex;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: #94a8c6;
  }

  .title {
    display: flex;
    font-size: 1.25em;
    line-height: 0.9em;

    min-width: 250px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);

    background-color: #292351;
    // background-image: linear-gradient(to right bottom,
    //     #3050da,
    //     #2658d9,
    //     #1e5fd8,
    //     #1c66d6,
    //     #1f6cd3);

    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: default;

    .primary {
      color: $kendo-color-primary;
    }
  }

  #breadcrumb {
    overflow: hidden;
  }

  .settings {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }

  .k-avatar {
    display: block;
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    box-shadow: 0 0 0px 2px $white;
  }

}