@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  font-family: "Open Sans";
  font-weight: 400;
}

h1 {
  font-size: 1.25rem;
  font-weight: 400;
}

h2 {
  font-size: 1rem;
  font-weight: 200;
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

a {
  font-size: 1rem;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 200;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.75rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 200;
  }
}