// Global variables here
$background-color: #fff;
$white: #ffffff;


$tiger-team: #ff6358;
$lemon-team: #ffd246;
$organic-team: #55ab1d;
$ocean-team: #28b4c8;

$headerHeight: 64px;

// custom style
$border-radius: 5px;

$default: #292351;
$kendo-color-primary: $default;
$kendo-color-secondary: #8deaff;
$kendo-color-info: #3e80ed;
$kendo-color-success: #5ec232;
$kendo-color-warning: #fdce3e;
$kendo-color-error: #d51923;
$kendo-color-body-text: #424242;
$kendo-color-body-bg: #ffffff;
$kendo-color-headings-text: #272727;

$series-a: #23bde0;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #2d73f5;
$series-f: #aa46be;

$silver: #eff2f8;
$darkSilver: #afb8c7;
$defaultLight: #4c7bea;
$defaultLightest: #bdccf0;

//absence color types
$absenceColorLeave: #78d237;
$absenceColorSickness: #ececec;
$absenceColorPending: #ffd246;
$absenceColorRejected: #d51923;
$absenceColorOnRequest: #24cbc8;

$leftPanelWidth: 285px;

$mobileBreakpoint: 1200px;

:root {
    --mobileBreakpoint: 1200px;
}