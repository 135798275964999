@media (min-width: $mobileBreakpoint) {
  #hamburgerMenu {
    display: none;
  }
}

@media (max-width: $mobileBreakpoint) {
  #hamburgerMenu {
    display: block;
    margin-right: 15px;
  }
}

.k-drawer-container {
  .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    height: 50px;
  }

  // .avatarBackground {
  //   background-image: url("./../assets/avatarBg.png");
  //   background-position-y: bottom;
  //   background-position-x: right;

  //   width: 110%;
  //   height: 245px;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   opacity: 0.3;
  //   z-index: -1;
  // }

  .avatarImage {
    margin-top: 20px !important;
    display: flex;
    background-color: rgba(255, 255, 255, 0.3) !important;

    width: 75px;
    height: 75px;
  }

  .avatarName {
    margin-top: 10px;
    font-weight: 400;
  }

  .avatarPosition {
    font-weight: 200;
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  .avatarBackground {
    background-image: url("./../assets/abstract1-bg.png");
    background-position-y: bottom;
    background-position-x: right;

    width: 110%;
    height: 245px;
    position: absolute;
    bottom: 0px;
    left: -200px;
    opacity: 0.15;
    z-index: -1;

    background-repeat: no-repeat;
  }

  .k-drawer {
    color: #fff;
    font-family: Montserrat, sans-serif !important;
    background-image: linear-gradient(210deg,
        hsl(247deg, 39%, 23%) 0%,
        hsl(237deg, 45%, 27%) 59%,
        hsl(232deg, 52%, 29%) 78%,
        hsl(229deg, 59%, 32%) 90%,
        hsl(227deg, 64%, 34%) 100%);

    // background-image: linear-gradient(to left bottom, #1f66cd, #105bda, #254ce3, #4337e9, #6201ea);
  }

  &.k-drawer-push .k-drawer {
    position: sticky;
    top: $headerHeight;
    height: calc(100vh - #{$headerHeight});
  }

  .k-drawer-item {
    margin-left: 15px;

    user-select: none;
    transition: background-color 0.5s;

    .itemIcon {
      font-size: 20px;
    }

    .itemText {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // small arrows in left menu
    .k-icon.itemIcon.k-i-arrow-60-right {
      margin-top: -15px;
    }

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .nestedDrawerItem {
    height: 10px;
    margin-left: 30px;
    padding: 15px !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .k-drawer-item:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: #fff;
  }

  .k-drawer-item:active,
  .k-drawer-item.k-state-selected,
  .k-drawer-item.k-selected,
  .k-drawer-item.k-state-selected:hover {
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
  }

  .k-drawer-item[role="option"] {
    padding: 7px 8px;
  }

  .k-drawer-content {
    height: calc(100vh - #{$headerHeight});
    overflow-y: scroll;
  }
}