.mdlRoot {
    height: calc(100vh - $headerHeight);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mdlMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 1rem;
}

.mdlToolbar {
    display: flex;
    align-items: center;
    padding: 1em 0 0.5em 0;
}

.mdlDataView {
    flex: 1;
    overflow: hidden;

    .k-grid {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .k-grid-content {
        flex: 1;
        overflow-y: auto;
    }

    .k-grid-header {
        padding-right: 17px;
    }

    .k-grid-pager {
        margin-top: auto;
    }
}