.k-in.k-state-selected {
  background-color: $default !important;
}

.k-button.k-button-outline {
  border-radius: 2px;
  color: $default !important;
}

.k-button.k-button-outline:hover {
  color: $white !important;
  background-color: $default !important;
}

.k-button.red:hover {
  color: $white !important;
  background-color: red !important;
}

.k-drawer-content {
  overflow-y: auto !important;
}

.k-drawer-separator {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.k-drawer-item:focus,
.k-drawer-item.k-state-focused,
.k-drawer-item.k-focus {
  background: none;
  box-shadow: none;
}

.k-form-hint {
  color: rgb(157, 157, 157);
}

.k-label {
  font-weight: 500;
}

.k-header {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.k-item {
  color: #000;
}

.k-window-title {
  font-size: 1.1em !important;
}

.k-toolbar {
  min-height: 3.5em;
  width: 100%;
}

.k-tabstrip,
.k-tabstrip-items-wrapper,
.k-item.k-active,
.k-tabstrip>.k-content.k-active {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.k-item.k-active {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.k-breadcrumb {
  margin-left: 15px;
}


.k-button-outline-base.k-selected {
  background-color: $default !important;
  color: $white !important;
}

.k-notification-group {
  position: absolute;
  animation: flyinanim ease 0.3s forwards;
  top: 0;
  left: 50%;
  z-index: 5000;
}

.k-notification-group .k-notification {
  padding: 15px;
}

@keyframes flyinanim {
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, 25%);
  }
}

.k-filtercell-operator {
  display: none !important;
  // background-color: red;
}

.k-scheduler-monthview {
  // Fix for calendar view: Occasionally, a 1px horizontal scrollbar would appear.
  overflow-x: hidden;
}

#gridUserLimitsPreview .k-master-row td:nth-of-type(2) {
  font-weight: bold !important;
}