.goToRegistrationButton {
    // background: #FF7700 0% 0% no-repeat;
    border-radius: 22px;
    padding: 15px 35px 15px 35px;
    max-width: 300px;
    margin-top: 35px;
    text-align: center;
    display: inline-block;

    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0px 0px 1px 2px #2376df;

    opacity: 0.5;
}

.goToRegistrationButton:hover {
    background: #FFA500;
    box-shadow: 0px 0px 5px 2px #FFA500AA;
    opacity: 1
}

.goToRegistrationButton>a {
    color: #2376df !important;
    text-decoration: none;
}

.goToRegistrationButton:hover>a {
    color: #fff !important;
    text-decoration: none;
}