@media only screen and (max-width: 1279px) {
  .pageWrapper {
    flex-direction: column-reverse;
    overflow-y: scroll;

    .formColumn {
      width: 100% !important;
      height: 70% !important;
      justify-content: flex-start !important;
      min-height: 400px;

      .formHeader {
        font-size: 1.5em !important;
        margin-bottom: 0;
      }
    }

    .productDescColumn {
      background: linear-gradient(45deg, #2376df, #78a9e8) !important;

      width: 100% !important;
      padding-top: 1em;
      padding-bottom: 1em;
      height: 30% !important;
      min-height: 350px;

      .slogan {
        font-size: 2em !important;
        font-weight: 700;
      }

      .sloganContentText {
        width: 75% !important;
      }

      .description {
        font-size: 1em !important;
      }

      .bigOrnament,
      .smallOrnament {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .smallOrnament {
    margin-left: -0.35em !important;
  }

  .sloganContentText {
    width: 65% !important;
  }

  @keyframes waving {
    from {
      margin-left: -0.6em;
    }

    to {
      margin-left: -0.75em;
    }
  }
}

@media screen and (min-width: 1600px) {
  .smallOrnament {
    margin-left: -0.3em !important;
  }

  @keyframes waving {
    from {
      margin-left: -1em;
    }

    to {
      margin-left: -1.2em;
    }
  }
}

.pageWrapper {
  display: flex;
  height: 100%;

  .licenceNote {
    margin-top: 30px;
    border-top: 1px dotted gray;
    text-decoration: none;
    font-size: 0.8rem;
    text-align: justify;

    a {
      font-size: 0.8rem;
      color: #2376df;
    }
  }

  .formColumn {
    width: 45%;
    height: 100%;
    margin: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .formHeader {
      font-size: 2em;
    }
  }

  .productDescColumn {
    width: 55%;
    height: 100%;
    background-color: #2376df;

    .bigOrnament {
      animation: cubic-bezier(0.42, 0, 0.58, 1) infinite alternate;
      animation-name: waving;
      animation-duration: 25s;

      font-size: 18em;
      position: absolute;
      margin-left: -1.2em;
      top: 0.1em;
      background-color: orange;
      height: 1em;
      width: 0.7em;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    }

    .smallOrnament {
      font-size: 18em;
      position: absolute;
      margin-left: -0.5em;
      top: 0.2em;
      background-color: orange;
      height: 0.5em;
      width: 0.3em;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    }

    .sloganWrapper {
      color: #fff;

      margin: 0px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .slogan {
        font-size: 4em;
        font-weight: 700;
      }

      .description {
        font-size: 1.25em;
        line-height: 1.5em;
        text-align: justify;
      }
    }

    .sloganContentText {
      width: 55%;
    }
  }
}