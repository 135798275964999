body {
  @extend .noSelect;
  margin: 0;
  padding: 0;
  list-style-type: none;

  scrollbar-color: red;
}

html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

.marginRight15px {
  margin-right: 15px;
}

.marginRight10px {
  margin-right: 10px;
}

.marginRight5px {
  margin-right: 5px;
}

.cursorHand {
  cursor: pointer;
}

label.c-required {
  font-weight: bold;
}

label.c-required:after {
  content: " *";
  color: red;
}

.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@import "./styles/main.scss";
@import './styles/shared/modernLayout.scss';