@import "./variables";

$toolbarHeight: 6em;

@media (max-width: $mobileBreakpoint) {
  .hideOnMobile {
    display: none !important;
  }
}

.mainToolbar {
  min-height: $toolbarHeight;
  width: 100%;
}

.belowToolbarWrapper {
  height: calc(100% - #{$toolbarHeight / 2});
  width: 100%;
}

.fullWidthColumn {
  overflow-x: hidden;
  width: 100%;
  padding: 15px 20px 0px 15px;
  border: 1px dotted $silver;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  background-image: linear-gradient(to left,
      #f5faff,
      #f5f9fc,
      #fdfeff,
      #fefeff,
      #ffffff);
}

.highlighted {
  color: $default;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 25px;
}

@keyframes blinker {
  25% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 1;
  }
}

.itemHeader {
  font-weight: 500;
  color: $default;
  min-height: 2.5em;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;
}

.fullPageContentWrapper {
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  .k-tabstrip,
  .k-animation-container {
    width: 100%;
  }
}

.errorText {
  color: red;
}

.fullHeight {
  max-height:calc(100vh - #{$toolbarHeight});
}

/* calendar legend */
.legend {
  list-style: none;
  display: inline-flex;
}

.legend li {
  float: left;
  margin-right: 10px;
}

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px;
}



/* awaiting leaves tab */
#leaveList ul.k-tabstrip-items li:nth-child(1)[hasData=true] {
  font-weight: bold;
  color: $kendo-color-primary;

  &::after {
    content: '🔔';
    color: red;
    margin-top: 5px;
    margin-left: -10px;
    animation: blinker 1s linear infinite;
  }
}

.tutorial div.hidePrevButton+div[dir=ltr]>button:nth-child(1) {
  display: none !important;
}

.tutorial div.hideNextButton+div[dir=ltr]>button:nth-child(2) {
  display: none !important;
}

// ---

.flexItemsRight {
  display: flex;
  justify-content: flex-end;
}

.img-wrap {
  position: relative;
  margin-top: 1em;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.striketrough {
  text-decoration: line-through;
}